import { AdjustViewport } from './AdjustViewport';
import { SetRootProperty } from './SetRootProperty';
import { RewritingAtag } from './RewritingAtag';
import { RegisterCustomEvent } from './RegisterCustomEvent';
import { SmoothScroll } from './SmoothScroll';
import { MobileNav } from './MobileNav';
import { ClickPassword } from './ClickPassword';
import { MegaMenu } from './MegaMenu';
import { Accordion } from './Accordion';
import { Modal } from './Modal';
import { CustomScrollbar } from './CustomScrollbar';
const COMMON = {
  init() {
    console.log('COMMON');
    RegisterCustomEvent();
    AdjustViewport();
    SetRootProperty();
    RewritingAtag();
    SmoothScroll();
    MobileNav.init();
    ClickPassword.init();
    MegaMenu.init();
    Accordion.init();
    Modal.init();
    CustomScrollbar.init();
  },
  resize() {
    AdjustViewport();
    SetRootProperty();
  },
  breakPoint() {
    MobileNav.breakPoint();
  },
};
export { COMMON };
