import { gsap } from 'gsap';
export const Accordion = {
  init() {
    const btns = document.querySelectorAll('.js-acdn');
    if (btns.length) {
      btns.forEach((btn) => {
        const id = btn.getAttribute('aria-controls');
        if (!id) {
          console.error(`Accordion Error: Missing 'aria-controls' attribute on`, btn);
          return;
        }
        const cont = document.getElementById(id);
        if (!cont) {
          console.error(`Accordion Error: No element found with ID '${id}' for`, btn);
          return;
        }
        btn.addEventListener('click', () => {
          btn.classList.toggle('is-open');

          if (btn.classList.contains('is-open')) {
            gsap.to(cont, {
              height: 'auto',
              duration: 0.3,
            });
          } else {
            gsap.to(cont, {
              height: '0',
              duration: 0.3,
            });
          }
        });
      });
    }
  },
};
