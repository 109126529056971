import gsap from 'gsap';
import lodash from 'lodash';
export const Modal = {
  init() {
    const btns = document.querySelectorAll('.js-modal[aria-controls]');
    if (btns.length) {
      btns.forEach((btn) => {
        const cont = document.getElementById(btn.getAttribute('aria-controls'));
        if (lodash.isElement(cont)) {
          btn.addEventListener('click', () => {
            if (!lodash.isNil(btn.dataset.modalYt)) {
              this.setYt(cont, btn.dataset.modalYt);
            }
            gsap.to(cont, {
              autoAlpha: 1,
            });
          });

          const closes = cont.querySelectorAll('.js-modal-close');
          if (closes.length == 0) {
            console.error(`#${cont.id}を閉じるボタン（.js-modal-close）がありません。`);
            return;
          }
          closes.forEach((el) => {
            el.addEventListener('click', () => {
              if (!lodash.isNil(btn.dataset.modalYt)) {
                this.removeYt(cont);
              }
              gsap.to(cont, {
                autoAlpha: 0,
              });
            });
          });
        }
      });
    }
  },
  /**
   * @function setYt
   * @param {Element} modal
   * @param {String} src
   */
  setYt(modal, src) {
    const iframe = modal.querySelector('iframe');
    iframe.setAttribute('src', src);
  },
  removeYt(modal) {
    const iframe = modal.querySelector('iframe');
    iframe.setAttribute('src', '');
  },
};
