import SimpleBar from 'simplebar';
import 'simplebar/dist/simplebar.css';

export const CustomScrollbar = {
  init() {
    const els = document.querySelectorAll('.js-custom-scrollbar');
    els.forEach((el) => {
      new SimpleBar(el);
    });
  },
};
