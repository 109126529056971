import { gsap, ScrollToPlugin } from 'gsap/all';
gsap.registerPlugin(ScrollToPlugin);

/**
 * @function ScrollToHash
 * @param {string!} hashtag ### Element ID
 * @param {Event} event ### aタグをクリックしたときにeventキャンセル
 */

const ScrollToHash = (hashtag, event) => {
  const element = hashtag !== '' ? document.querySelector(hashtag) : '';
  const smoothScrollDuration = 0.5;
  const offset = document.querySelector('.l-header') ? document.querySelector('.l-header').clientHeight : document.querySelector('.l-sp-header') ? document.querySelector('.l-sp-header').clientHeight : 0;
  console.log(offset);
  if (element !== '') {
    if (event) {
      event.preventDefault();
      gsap.to(window, {
        duration: smoothScrollDuration,
        scrollTo: { y: element, offsetY: offset },
      });
    } else {
      gsap.to(window, {
        duration: smoothScrollDuration,
        scrollTo: { y: element, offsetY: offset },
      });
    }
  }
};

/**
 * @function IsTouchDevice
 * @description タッチデバイスかどうかを判定する
 */
const IsTouchDevice = () => {
  const isTouchDevice = 'ontouchstart' in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0 || window.matchMedia('(pointer: coarse)').matches || navigator.userAgentData?.mobile || false;
  return isTouchDevice;
};

/**
 * @function IsMobileOrTablet
 * @description デバイスがモバイル/タブレットの場合にtrueを返す
 */
const IsMobileOrTablet = () => {
  return 'ontouchstart' in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0;
};

/**
 * @function GetDeviceType
 * @description "smartphone", "tablet", "pc" のいずれかを返す
 */
const GetDeviceType = () => {
  const userAgent = navigator.userAgent.toLowerCase();
  const isTablet = /(ipad|tablet|nexus 7|playbook|silk)|(android(?!.*mobi))/i.test(userAgent);
  const isMobile = /iphone|ipod|android.*mobile|windows phone|blackberry.*mobile/i.test(userAgent);

  if (isTablet) {
    return 'tablet';
  }
  if (isMobile) {
    return 'smartphone';
  }
  return 'pc';
};

export { ScrollToHash, IsTouchDevice, IsMobileOrTablet, GetDeviceType };
