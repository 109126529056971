const AdjustViewport = () => {
  const triggerWidth = 375;
  const viewport = document.querySelector('meta[name="viewport"]');
  if (viewport.getAttribute('content') === 'width=device-width, initial-scale=1.0, maximum-scale=1.0') {
    const value = window.outerWidth < triggerWidth ? `width=${triggerWidth}, target-densitydpi=device-dpi` : 'width=device-width, initial-scale=1,maximum-scale=1.0';
    viewport.setAttribute('content', value);
  }
};

export { AdjustViewport };
