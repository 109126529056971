import { gsap } from 'gsap';
import { IsTouchDevice } from '../utils';
let currentOpenId = '',
  isReadyCloseObj = {};
export const MegaMenu = {
  init() {
    const toggles = document.querySelectorAll('.js-mm-toggle');
    if (toggles.length) {
      toggles.forEach((toggle) => {
        if (IsTouchDevice()) {
          toggle.addEventListener('click', () => {
            this.toggle(toggle.getAttribute('aria-controls'));
          });
        } else {
          const id = toggle.getAttribute('aria-controls');
          isReadyCloseObj[id] = false;
          const megaMenu = document.getElementById(id);
          const megaMenuContainer = megaMenu.querySelector('.c-megamenu__container');
          toggle.addEventListener('mouseenter', () => {
            isReadyCloseObj[id] = false;
            this.open(id);
          });
          toggle.addEventListener('mouseleave', () => {
            isReadyCloseObj[id] = true;
            setTimeout(() => {
              if (isReadyCloseObj[id]) {
                this.close(id);
              }
            }, 200);
          });
          megaMenuContainer.addEventListener('mouseenter', () => {
            isReadyCloseObj[id] = false;
            this.open(id);
          });
          megaMenuContainer.addEventListener('mouseleave', () => {
            isReadyCloseObj[id] = true;
            setTimeout(() => {
              if (isReadyCloseObj[id]) {
                this.close(id);
              }
            }, 200);
          });
        }
      });
    }
  },
  toggle(id) {
    if (currentOpenId != '') {
      this.close(currentOpenId);
      if (currentOpenId == id) {
        currentOpenId = '';
      } else {
        currentOpenId = id;
        this.open(id);
      }
    } else {
      currentOpenId = id;
      this.open(id);
    }
  },
  open(id) {
    const toggles = document.querySelectorAll(`.js-mm-toggle[aria-controls="${id}"]`);
    toggles.forEach((toggle) => {
      toggle.classList.add('is-active');
    });
    const megaMenu = document.getElementById(id);
    gsap.to(megaMenu, {
      duration: 0.3,
      autoAlpha: 1,
    });
  },
  close(id) {
    const toggles = document.querySelectorAll(`.js-mm-toggle[aria-controls="${id}"]`);
    toggles.forEach((toggle) => {
      toggle.classList.remove('is-active');
    });
    const megaMenu = document.getElementById(id);
    gsap.to(megaMenu, {
      duration: 0.3,
      autoAlpha: 0,
    });
  },
};
