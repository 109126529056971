import { IsMobileOrTablet } from '../utils';
export const Renewal = {
  async init() {
    if (document.getElementById('Header')) {
      const header = document.getElementById('Header');
      const headerFile = IsMobileOrTablet() ? '/include/sp_header.html' : '/include/pc_header.html';
      try {
        const response = await fetch(headerFile);
        if (!response.ok) throw new Error('Failed to load header');

        const headerHtml = await response.text();

        const tempContainer = document.createElement('div');
        tempContainer.innerHTML = headerHtml;

        header.replaceWith(...tempContainer.childNodes);
        console.log('header');
      } catch (error) {
        console.error('Error loading header:', error);
      }
    }
    if (document.getElementById('Footer')) {
      const footer = document.getElementById('Footer');
      const footerFile = IsMobileOrTablet() ? '/include/sp_footer.html' : '/include/pc_footer.html';
      try {
        const response = await fetch(footerFile);
        if (!response.ok) throw new Error('Failed to load footer');

        const footerHtml = await response.text();
        const tempContainer = document.createElement('div');
        tempContainer.innerHTML = footerHtml;
        footer.replaceWith(...tempContainer.childNodes);
        console.log('footer');
      } catch (error) {
        console.error('Error loading footer:', error);
      }
    }
  },
};
