import lodash from 'lodash';
import { COMMON } from './common';
import { Home } from './page/Home';
import { Renewal } from './page/Renewal';
(function () {
  window.addEventListener('DOMContentLoaded', async () => {
    await Renewal.init();
    console.log('done');
    COMMON.init();
    Home.init();
  });
  window.addEventListener(
    'resize',
    (lodash.debounce = () => {
      COMMON.resize();
    })
  );
  window.addEventListener('breakPoint', () => {
    COMMON.breakPoint();
  });
})();
