import lodash from 'lodash';
import { gsap } from 'gsap';
let isOpen = false;
const MobileNav = {
  init() {
    const btn = document.getElementById('menuToggle');
    if (lodash.isElement(btn)) {
      btn.addEventListener('click', () => {
        isOpen = this.toggleNav(isOpen);
      });
    }
  },
  toggleNav(isOpen) {
    const btn = document.getElementById('menuToggle');
    const nav = document.querySelector('.l-nav');
    if (isOpen) {
      gsap.to(nav, {
        autoAlpha: 0,
        duration: 0.3,
      });
      btn.classList.remove('is-active');
      document.body.classList.remove('is-fixed');
      return false;
    } else {
      gsap.to(nav, {
        autoAlpha: 1,
        duration: 0.3,
      });
      btn.classList.add('is-active');
      document.body.classList.add('is-fixed');
      return true;
    }
  },
  breakPoint(isMobile) {
    if (!isMobile && isOpen) {
      isOpen = this.toggleNav(isOpen);
    }
  },
};

export { MobileNav };
