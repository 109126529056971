import { Swiper } from 'swiper';
import { Navigation, Pagination, Autoplay } from 'swiper/modules';
import 'swiper/css';

export const Home = {
  init() {
    if (document.querySelector('.p-home-slider')) {
      new Swiper('.p-home-slider .swiper', {
        modules: [Navigation, Pagination, Autoplay],
        loop: true,
        slidesPerView: 'auto',
        centeredSlides: true,
        speed: 1500,
        spaceBetween: 23,
        initialSlide: 0,
        autoplay: {
          delay: 5000,
          disableOnInteraction: false,
        },
        navigation: {
          prevEl: document.querySelector('.p-home-slider__prev'),
          nextEl: document.querySelector('.p-home-slider__next'),
        },
        pagination: {
          el: document.querySelector('.p-home-slider__pagination'),
          type: 'bullets',
          bulletActiveClass: 'is-active',
          bulletClass: 'p-home-slider__bullet',
        },
        breakpoints: {
          768: {
            spaceBetween: 30,
          },
        },
      });
    }
  },
};
