import { gsap, ScrollToPlugin } from 'gsap/all';
import { ScrollToHash } from '../utils';
gsap.registerPlugin(ScrollToPlugin);

const GetSamePageAnchor = (url) => {
  if (url.protocol !== window.location.protocol || url.host !== window.location.host || url.pathname !== window.location.pathname || url.search !== window.location.search) {
    return '';
  }
  return url.hash;
};

const SmoothScroll = () => {
  const alinks = document.querySelectorAll('a[href^="#"]');
  if (alinks.length) {
    for (let i = 0; i < alinks.length; i++) {
      alinks[i].addEventListener('click', (e) => {
        ScrollToHash(GetSamePageAnchor(alinks[i]), e);
      });
    }
  }
  if (location.hash) {
    ScrollToHash(location.hash);
  }
};

export { SmoothScroll };
