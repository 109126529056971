import { IsMobileOrTablet } from '../utils';
const rewritingAtag = (el) => {
  const attributes = el.attributes;
  const innerHtml = el.innerHTML;
  let attributesText = null;
  for (let i = 0; i < attributes.length; i++) {
    const attribute = attributes[i];
    if (attribute.name != 'href') {
      const t = `${attribute.name}="${attribute.textContent}"`;
      attributesText = attributesText == null ? t : `${t} ${attributesText}`;
    }
  }
  if (attributesText == null) {
    el.outerHTML = `<span>${innerHtml}</span>`;
  } else {
    el.outerHTML = `<span ${attributesText}>${innerHtml}</span>`;
  }
};

const RewritingAtag = () => {
  const tellinks = document.querySelectorAll('a[href^="tel:"]');
  if (tellinks.length && !IsMobileOrTablet()) {
    tellinks.forEach((el) => {
      rewritingAtag(el);
    });
  }
};

export { RewritingAtag };
