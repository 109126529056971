import lodash from 'lodash';
const ROOT_STYLES = getComputedStyle(document.documentElement);
const BREAK_SUFFIXES = ['sm', 'md', 'lg', 'xl', '2xl'];
const BREAK_BASE = 'lg';
const BREAK_POINTS = Object.fromEntries(BREAK_SUFFIXES.map((suffix) => [suffix, parseInt(ROOT_STYLES.getPropertyValue(`--breakpoint-${suffix}`), 10) || 0]));
let currentDevice = window.innerWidth < BREAK_POINTS[BREAK_BASE] ? 'mobile' : 'pc';

const dispatchBreakPointEvent = (isMobile) => {
  window.dispatchEvent(
    new CustomEvent('breakPoint', {
      detail: { is_mobile: isMobile },
    })
  );
};

const handleResize = lodash.debounce(() => {
  const isMobile = window.innerWidth < BREAK_POINTS[BREAK_BASE];
  if ((isMobile && currentDevice !== 'mobile') || (!isMobile && currentDevice !== 'pc')) {
    currentDevice = isMobile ? 'mobile' : 'pc';
    dispatchBreakPointEvent(isMobile);
  }
}, 200);

const customEventBreakPoint = () => {
  window.addEventListener('resize', handleResize);
};

const RegisterCustomEvent = () => {
  customEventBreakPoint();
};

export { RegisterCustomEvent };
